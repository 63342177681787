<template>
  <section class="content-section bg-light" id="about">
    <div class="container px-4 px-lg-5 text-center">
      <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-lg-10">
          <h2>Privacy Policy</h2>
          <p class="lead mb-5">Last updated: 19 December, 2023</p>
          <p class="lead mb-5">
            This Privacy Policy sets out the policy which governs use of information you provide in connection with BenjiBot. The terms "you" and "your"
            refer to all individuals or entities using BenjiBot. The terms "we," "us," "our," refer to BenjiBot and "bot" refers to our Discord bot itself.
            If you have additional questions or require more information about our Privacy Policy, do not hesitate to ask by joining the bots support server.
          </p>
          <h4>Consent</h4>
          <p class="lead mb-5">
            By using BenjiBot, you hereby consent to our Privacy Policy and agree to its terms.
          </p>
          <h4>Information we collect</h4>
          <p class="lead mb-5">
            The personal information that you are asked to provide, and the reasons why you are asked to provide it,
            will be made clear to you at the point we ask you to provide your personal information. If you contact us directly,
            we may receive additional information about you such as your name, the contents of the message and/or attachments you
            may send us, and any other information you may choose to provide. When you use the bot, information that will be
            automatically collected are Discord user ID's, Discord usernames, Discord channel ID's, and Discord guild ID's.
            Other information may be collected through certain commands but this is optional.
          </p>
          <h4>How we use your information</h4>
          <p class="lead mb-5">
            We use the information we collect in various ways, including to:
          </p>
          <ul class="lead mb-5" style="display:inline-block;text-align:justify;">
            <li>Provide, operate, and maintain our bot.</li>
            <li>Improve, personalise, and expand our bot.</li>
            <li>Understand and analyse how you use our bot.</li>
            <li>Develop new features, and functionality.</li>
          </ul>
          <p class="lead mb-5">
            BenjiBot follows a procedure of logging commands used. These commands are logged when executed.
            Information collected on each log are Discord user ID's, Discord username's, command names, arguments provided (if applicable),
            and the date's the command was used.
          </p>
          <h4>Third Party Privacy Policies</h4>
          <p class="lead mb-5">
            BenjiBot's Privacy Policy does not apply to Discord as a whole or any third party API's that the bot may use. Third party APIs include: Apple WeatherKit, Last.fm, Genius, Bing, and TimezoneDB.
            Please note that BenjiBot has no access to or control over how information that Discord collects is used by third-party advertisers.
            Thus, we are advising you to consult the respective <a href="https://discord.com/privacy">Privacy Policies</a> and <a href="https://discord.com/terms">Terms of Service</a> of
            Discord for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
          </p>
          <h4>CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
          <p class="lead mb-5">
            Under the CCPA, among other rights, California consumers have the right to:
          </p>
          <ul class="lead mb-5" style="display:inline-block;text-align:justify;">
            <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
            <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
            <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
          </ul>
          <p class="lead mb-5">
            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us through our <a href="help">support server.</a>.
          </p>
          <h4>GDPR Data Protection Rights</h4>
          <ul class="lead mb-5" style="display:inline-block;text-align:justify;">
            <li>The right to access – You have the right to request copies of your personal data.</li>
            <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate.
              You also have the right to request that we complete the information you believe is incomplete.
            </li>
            <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
            <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
            <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
            <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organisation,
              or directly to you, under certain conditions. If you make a request, we have one month to respond to you. If you would like
              to exercise any of these rights, please contact us.
            </li>
          </ul>
          <h4>Children's Information</h4>
          <p class="lead mb-5">
            Another part of our priority is adding protection for children while using the internet.
            We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. BenjiBot does not
            knowingly collect any Identifiable Information from children under the age of 13. Please see the <a href="https://discord.com/terms">Discord Terms of Service</a> for more information.
            If you think that your child provided this kind of information through the bot, we strongly encourage you to contact us immediately through our <a href="help">support server</a> and we will
            do our best efforts to promptly remove such information from our records.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
  name: "PrivacyView",
  setup() {
    useHead({
      title: "BenjiBot - Privacy",
      meta: [
        { name: "title", content: "BenjiBot - Privacy Policy" },
        { name: "description", content: "BenjiBot | Privacy Policy" },
      ],
    })
  }
}
</script>
