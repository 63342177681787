<template>
  <body id="page-top">
    <header class="masthead d-flex align-items-center">
      <div class="container px-4 px-lg-5 text-center">
        <h1 class="mb-1">BenjiBot</h1>
        <h3 class="mb-5">A multi-purpose Discord bot designed to help improve your server with moderation, saves, last.fm, and more.</h3>
        <div class="row">
          <div class="col text-center">
            <router-link :to="{ name: 'Invite' }" v-slot="{navigate}">
              <button class="btn btn-primary btn-xl btn-padding" @click="navigate">Invite</button>
            </router-link>
            <router-link :to="{ name: 'Help' }" v-slot="{navigate}">
              <button class="btn btn-primary btn-xl btn-padding" @click="navigate">Support Server</button>
            </router-link>
            <router-link :to="{ name: 'Privacy' }" v-slot="{navigate}">
              <button class="btn btn-primary btn-xl btn-padding" @click="navigate">Privacy Policy</button>
            </router-link>
            <router-link :to="{ name: 'Terms' }" v-slot="{navigate}">
              <button class="btn btn-primary btn-xl btn-padding" @click="navigate">Terms of Service</button>
            </router-link>
          </div>
        </div>
      </div>
    </header>
  </body>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
  name: "HomeView",
  setup() {
    useHead({
      title: "BenjiBot - Home",
      meta: [
        { name: "title", content: "BenjiBot - Home" },
        { name: "description", content: "A multi-purpose Discord bot designed to help improve your server with moderation, saves, last.fm, and more." },
      ],
    })
  },
}
</script>