<script>
import { useHead } from "@vueuse/head";

export default {
  name: "HeadInformation",
  setup() {
    useHead({
      htmlAttrs: { lang: 'en', amp: false },
      meta: [
        { charset: "utf-8" },
        { name: "theme-color", content: "#5865F2" },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },

        // Open Graph
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://www.benjibot.xyz/" },
        { property: "og:name", content: "BenjiBot - Discord Bot" },
        { property: "og:title", content: "BenjiBot - A multi-purpose Discord bot designed to help improve your server with moderation, saves, last.fm, and more." },

        // Twitter
        { name: "twitter:card", content: "summary_large_image" },
        { property: "twitter:url", content: "https://www.benjibot.xyz/" },
        { property: "twitter:title", content: "BenjiBot - Discord Bot" },
        { property: "twitter:description", content: "BenjiBot - A multi-purpose Discord bot designed to help improve your server with moderation, saves, last.fm, and more." }
      ],
      link: [
        { rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/css/simple-line-icons.min.css" },
        { rel: "stylesheet", type:"text/css", href: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,300italic,400italic,700italic" },
        { rel: "icon", type: "image/x-icon", href: "favicon.ico" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "favicon-16x16.png" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "favicon-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "192x192", href: "android-chrome-192x192.png" },
        { rel: "icon", type: "image/png", sizes: "512x512", href: "android-chrome-512x512.png" },
        { rel: "apple-touch-icon", sizes: "180x180", href: "apple-touch-icon.jpg" },
      ],
      script: [
        { src: "https://use.fontawesome.com/releases/v5.15.4/js/all.js", crossorigin: "anonymous" }
      ]
    })
  },
}
</script>
