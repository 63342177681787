<template>
  <section class="content-section bg-light" id="about">
    <div class="container px-4 px-lg-5 text-center">
      <div class="container px-4 px-lg-5 text-center">
        <div class="row gx-4 gx-lg-5 justify-content-center">
          <div class="col-lg-10">
            <h2>Welcome To A New BenjiBot!</h2>
            <br/>
            <h4>What are Slash Commands?</h4>
            <p class="lead mb-5">
              If you use bots frequently on Discord or have used them in the past, you may notice a couple things have
              changed. First, bots can do all sorts of new things they haven’t been able to do before - that’s because
              Discord have released a number of new features for us to play with (buttons, modals, context menu commands!)
              and make our bots more accessible and fun.<br><br>
              Secondly, Discord has also been standardising how bot commands work, so that they’re easier for us to use.
              In a nutshell, the ‘old way’ was talking to a bot (e.g. sending a message, which it would read) to tell it
              what to do. The ‘new way’ is to type a slash “/” to bring up a menu of available commands in the server,
              so you can select the one you want. These are called Slash Commands.
            </p>
            <h4>New Tricks</h4>
            <ul class="lead mb-5" style="display:inline-block;text-align:justify;">
              <li>
                <b>Introducing the command picker!</b> When you type “/” a handy menu of available slash commands
                pops up. At the top, you’ll see commands you use most often.
                <div>
                  <br>
                  <img src="https://i.imgur.com/AFFWvXb.png" alt="BenjiBot Slash Command List" style="width: 100%; height: auto;"/>
                </div>
                <br>
              </li>
              <li>
                <b>Autocomplete for interactions.</b> Select BenjiBot commands, with support for autocomplete, will help
                you save some time typing out long phrases.
              </li>
              <br>
              <li>
                <b>Context menus can have commands in them!</b> Right-clicking on messages will bring up what is called
                a context menu. From here you can do things like quickly create a save or report a message to the
                moderators.
                <div>
                  <img src="https://i.imgur.com/96t56DM.png" alt="BenjiBot Context Menu Command" style="width: 100%; height: auto;"/>
                </div>
              </li>
              <li>
                <b>Command permissions system.</b> Moderators and admins can control where BenjiBot's slash commands and
                context menu commands can be used - what channels, and by whom - and even which commands can be used.
                <div>
                  <img src="https://i.imgur.com/ddsFQRQ.png" alt="BenjiBot Settings Menu" style="width: 100%; height: auto;"/>
                </div>
              </li>
            </ul>
            <h4>Why we are moving to Slash Commands?</h4>
            <p class="lead mb-5">
              When commands are developed from the ground up for Slash Commands they can provide an easy,
              intuitive, and powerful experience for new Discord users and even power users. Features such as
              Autocomplete, Buttons, and Modals can make the most complex commands simpler than ever. For
              example, BenjiBot's Poll command uses modals to make it super easy to enter a poll name and
              poll options. Before, this type of command experience wouldn't have been possible.
              <br><br>
              Another reason we've decided to move to Slash Commands is because Discord will be removing bot
              access to message content in early September. This means that bots cannot read the content of messages
              sent in servers, unless they are granted permission by Discord. Essentially, this means that
              most verified bots won't be able to recognise prefix based commands. If you'd like to learn more,
              please <a href="https://support-dev.discord.com/hc/en-us/articles/4404772028055-Message-Content-Privileged-Intent-FAQ">press here.</a>
            </p>
            <h4>Command Transition Guide</h4>
            <p class="lead mb-5">
              Transitions can be hard, so we've made a command transition guide to try and make things easier.
            </p>
            <table id="table">
              <tr>
                <th>Prefix Command</th>
                <th>Slash Command</th>
              </tr>
              <tr>
                <td>Count</td>
                <td>/count stats</td>
              </tr>
              <tr>
                <td>Count Start</td>
                <td>/count start</td>
              </tr>
              <tr>
                <td>Count Stop</td>
                <td>/count stop</td>
              </tr>
              <tr>
                <td>8ball</td>
                <td>/8ball</td>
              </tr>
              <tr>
                <td>Cat</td>
                <td>/cat</td>
              </tr>
              <tr>
                <td>Clap</td>
                <td>/clap</td>
              </tr>
              <tr>
                <td>Dog</td>
                <td>/dog</td>
              </tr>
              <tr>
                <td>Joke</td>
                <td>/joke</td>
              </tr>
              <tr>
                <td>Meme</td>
                <td>/meme</td>
              </tr>
              <tr>
                <td>Pun</td>
                <td>/pun</td>
              </tr>
              <tr>
                <td>Sponge</td>
                <td>/sponge</td>
              </tr>
              <tr>
                <td>Taylor</td>
                <td>/taylor</td>
              </tr>
              <tr>
                <td>Ban</td>
                <td>/ban add</td>
              </tr>
              <tr>
                <td>Ban Temp</td>
                <td>/ban add</td>
              </tr>
              <tr>
                <td>Ban Remove/Unban</td>
                <td>/ban remove</td>
              </tr>
              <tr>
                <td>Mute</td>
                <td>/mute add</td>
              </tr>
              <tr>
                <td>Mute Temp</td>
                <td>/mute add</td>
              </tr>
              <tr>
                <td>Mute Remove/Unmute</td>
                <td>/mute remove</td>
              </tr>
              <tr>
                <td>Mute CreateRole</td>
                <td>/mute create-role</td>
              </tr>
              <tr>
                <td>Mute AddChannel</td>
                <td>/mute add-channel</td>
              </tr>
              <tr>
                <td>Mute RemoveChannel</td>
                <td>/mute remove-channel</td>
              </tr>
              <tr>
                <td>Warn</td>
                <td>/warn add</td>
              </tr>
              <tr>
                <td>Warn Get</td>
                <td>/warn get</td>
              </tr>
              <tr>
                <td>Warn Remove</td>
                <td>/warn remove</td>
              </tr>
              <tr>
                <td>Purge</td>
                <td>/purge</td>
              </tr>
              <tr>
                <td>Kick</td>
                <td>/kick</td>
              </tr>
              <tr>
                <td>Lastfm</td>
                <td>/lastfm current</td>
              </tr>
              <tr>
                <td>Lastfm Albums</td>
                <td>/lastfm albums</td>
              </tr>
              <tr>
                <td>Lastfm Artists</td>
                <td>/lastfm artists</td>
              </tr>
              <tr>
                <td>Lastfm Collage</td>
                <td>/lastfm collage</td>
              </tr>
              <tr>
                <td>Lastfm Profile</td>
                <td>/lastfm profile</td>
              </tr>
              <tr>
                <td>Lastfm Remove</td>
                <td>/lastfm remove</td>
              </tr>
              <tr>
                <td>Lastfm Set</td>
                <td>/lastfm set</td>
              </tr>
              <tr>
                <td>Lastfm Tracks</td>
                <td>/lastfm tracks</td>
              </tr>
              <tr>
                <td>Lyrics</td>
                <td>/lyrics</td>
              </tr>
              <tr>
                <td>Cointoss</td>
                <td>/cointoss</td>
              </tr>
              <tr>
                <td>Points</td>
                <td>/points</td>
              </tr>
              <tr>
                <td>TayRoll</td>
                <td>/tayroll</td>
              </tr>
              <tr>
                <td>Approve</td>
                <td>/save approve</td>
              </tr>
              <tr>
                <td>Delete</td>
                <td>/delete approve</td>
              </tr>
              <tr>
                <td>Get/G</td>
                <td>/save get</td>
              </tr>
              <tr>
                <td>List</td>
                <td>/save list</td>
              </tr>
              <tr>
                <td>Metadata</td>
                <td>/save metadata</td>
              </tr>
              <tr>
                <td>Random</td>
                <td>/save random</td>
              </tr>
              <tr>
                <td>Save</td>
                <td>/save create</td>
              </tr>
              <tr>
                <td>Unapproved</td>
                <td>/save unapproved</td>
              </tr>
              <tr>
                <td>Disable</td>
                <td>/command disable channel</td>
              </tr>
              <tr>
                <td>Disable Server</td>
                <td>/command disable server</td>
              </tr>
              <tr>
                <td>Enable</td>
                <td>/command enable channel</td>
              </tr>
              <tr>
                <td>Enable Server</td>
                <td>/command enable server</td>
              </tr>
              <tr>
                <td>Log</td>
                <td>/log show</td>
              </tr>
              <tr>
                <td>Log Start</td>
                <td>/log start messages | /log start members | /log start moderation</td>
              </tr>
              <tr>
                <td>Log Stop</td>
                <td>/log stop messages | /log stop members | /log stop moderation</td>
              </tr>
              <tr>
                <td>Autorole</td>
                <td>/autorole show | /autorole add</td>
              </tr>
              <tr>
                <td>Autorole Remove </td>
                <td>/autorole remove</td>
              </tr>
              <tr>
                <td>Avatar</td>
                <td>/avatar</td>
              </tr>
              <tr>
                <td>BotInfo</td>
                <td>/bot-information</td>
              </tr>
              <tr>
                <td>Help</td>
                <td>/help</td>
              </tr>
              <tr>
                <td>Rank</td>
                <td>/rank</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>/status</td>
              </tr>
            </table>
            <br>
            <h4>Questions</h4>
            <p class="lead mb-5">
              If you have any questions regarding this change, then please ask in our <a href="help">support server</a>
              where we'll try our best to answer your questions.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
  name: "SlashCommandView",
  setup() {
    useHead({
      title: "BenjiBot - Slash Commands",
      meta: [
        { name: "title", content: "BenjiBot - Slash Commands" },
        { name: "description", content: "BenjiBot | Getting Started With Slash Commands" },
      ],
    })
  }
}
</script>

<style scoped>
  #table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #table td, #table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #table tr:nth-child(even){background-color: #f2f2f2;}

  @media (prefers-color-scheme: dark) {
    #table tr:nth-child(even){
      background-color: black;
    }
    #table tr:hover {
      background-color: lightgray;
      color: black;
    }
  }

  #table tr:hover {background-color: #ddd;}

  #table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #5865F2;
    color: white;
  }
</style>
