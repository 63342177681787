<template>
  <head-information />
  <navigation-bar v-if="routeName !== 'Home'" />
  <router-view />
  <copyright-footer />
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import CopyrightFooter from "@/components/CopyrightFooter";
import HeadInformation from "@/components/HeadInformation";
import { computed } from "vue";
import { useRoute } from "vue-router";

export default {
  name: 'App',
  components: {
    HeadInformation,
    NavigationBar,
    CopyrightFooter,
  },
  setup() {
    const routeName = computed(() =>
        useRoute().name
    )
    return { routeName };
  }
}
</script>

<style>
  @import "./assets/css/styles.css";
</style>
