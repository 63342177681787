<template>
  <footer class="footer text-center">
    <div class="container px-4 px-lg-5">
      <p class="text-muted small mb-0">BenjiBot</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "CopyrightFooter"
}
</script>