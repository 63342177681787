import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView";
import PrivacyView from "@/views/PrivacyView";
import TermsView from "@/views/TermsView";
import PageNotFoundView from "@/views/PageNotFoundView";
import SlashCommandView from "@/views/SlashCommandView";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
    },
    {
        path: '/invite',
        name: 'Invite',
        beforeEnter() {
            window.location.replace("https://discord.com/oauth2/authorize?client_id=622420961982939176")
        }
    },
    {
        path: '/help',
        name: 'Help',
        beforeEnter() {
            window.location.replace("https://discord.com/invite/pMXmE6tCU8")
        }
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: PrivacyView,
    },
    {
        path: '/terms',
        name: 'Terms',
        component: TermsView
    },
    {
        path: '/slash-commands',
        name: 'SlashCommands',
        component: SlashCommandView
    },
    {
        path: "/:catchAll(.*)",
        component: PageNotFoundView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
})

export default router
