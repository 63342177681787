<template>
  <body id="page-top">
    <header class="masthead d-flex align-items-center">
      <div class="container px-4 px-lg-5 text-center">
        <h1 class="mb-1">Page Not Found</h1>
        <br>
        <div class="row">
          <div class="col text-center">
            <router-link :to="{ name: 'Home' }" v-slot="{navigate}">
              <button class="btn btn-primary btn-xl btn-padding" @click="navigate">Go Home</button>
            </router-link>
          </div>
        </div>
      </div>
    </header>
  </body>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
  name: "PageNotFoundView",
  setup() {
    useHead({
      title: "BenjiBot - Page Not Found",
      meta: [
        { name: "title", content: "BenjiBot - Page Not Found" },
        { name: "description", content: "BenjiBot - Page Not Found" },
      ],
    })
  },
}
</script>