<template>
  <a class="menu-toggle rounded" href="#"><i class="fas fa-bars"></i></a>
  <nav id="sidebar-wrapper">
    <ul class="sidebar-nav" id="sidebar-nav">
      <li class="sidebar-brand"><router-link :to="{ name: 'Home' }">BenjiBot</router-link></li>
      <li class="sidebar-nav-item"><router-link :to="{ name: 'Invite' }">Invite</router-link></li>
      <li class="sidebar-nav-item"><router-link :to="{ name: 'Help' }">Support Server</router-link></li>
      <li class="sidebar-nav-item"><router-link :to="{ name: 'Privacy' }">Privacy Policy</router-link></li>
      <li class="sidebar-nav-item"><router-link :to="{ name: 'Terms' }">Terms of Service</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavigationBar",
  mounted() {
      const sidebarWrapper = document.getElementById("sidebar-wrapper");
      // Close the sidebar menu when an item has been pressed
      const sidebarLink = document.getElementById("sidebar-nav");
      sidebarLink.addEventListener("click", () => {
        sidebarWrapper.classList.remove("active");
        menuToggle.classList.remove("active");
        _toggleMenuIcon();
      });

      // Closes the sidebar menu
      const menuToggle = document.body.querySelector('.menu-toggle');
      menuToggle.addEventListener("click", event => {
        event.preventDefault();
        sidebarWrapper.classList.toggle("active");
        _toggleMenuIcon();
        menuToggle.classList.toggle("active");
      })

      // Closes responsive menu when a scroll trigger link is clicked
      const scrollTriggerList = [].slice.call(document.querySelectorAll('#sidebar-wrapper .js-scroll-trigger'));
      scrollTriggerList.map(scrollTrigger => {
        scrollTrigger.addEventListener("click", () => {
          sidebarWrapper.classList.remove("active");
          menuToggle.classList.remove("active");
          _toggleMenuIcon();
        })
      });

      function _toggleMenuIcon() {
        const menuToggleBars = document.body.querySelector('.menu-toggle > .fa-bars');
        const menuToggleTimes = document.body.querySelector('.menu-toggle > .fa-times');
        if (menuToggleBars) {
          menuToggleBars.classList.remove("fa-bars");
          menuToggleBars.classList.add("fa-times");
        }
        if (menuToggleTimes) {
          menuToggleTimes.classList.remove("fa-times");
          menuToggleTimes.classList.add("fa-bars");
        }
      }
  }
}
</script>