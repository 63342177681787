<template>
  <section class="content-section bg-light" id="about">
    <div class="container px-4 px-lg-5 text-center">
      <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-lg-10">
          <h2>Terms of Service</h2>
          <p class="lead mb-5">Last updated: 19 December, 2023</p>
          <p class="lead mb-5">
            This Terms of Service constitute a legally binding agreement made between you, whether personally or on behalf of an entity and BenjiBot. The
            terms "you" and "your" refer to all individuals or entities using BenjiBot. The terms "we," "us," "our," refer to BenjiBot and "bot" refers to
            our Discord bot itself. You agree that by using the bot, you have read, understood, and agreed to be bound by all of these Terms of Service. IF
            YOU DO NOT AGREE WITH ALL OF THESE TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE BOT AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </p>
          <h4>Intellectual Property Rights</h4>
          <p class="lead mb-5">
            Unless otherwise indicated, the bot, logo, and all bot source code are owned by us, and are protected by copyright and trademark laws and
            various other intellectual property rights. Provided that you are eligible to use this website, you are granted access to download or print
            a copy of any portion of the Content to which you have properly gained access solely for your personal use.
          </p>
          <h4>User Representations</h4>
          <p class="lead mb-5">
            By using the bot, you represent and warrant that: (1) you have the legal capacity, and you agree to comply with these Terms of Service; (2) you
            are not under the age of 13 (3) you will not use the bot through automated or non-human means, whether through a bot, script, or otherwise;
            (4) you will not use the bot for any illegal or unauthorized purpose; and (5) your use of the bot will not violate any applicable law or
            regulation. If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to ban you from using
            the bot (or any portion thereof).
          </p>
          <h4>Prohibited Activities</h4>
          <p class="lead mb-5">
            You may not use the bot for any purpose other than that for which we make the bot available.<br>
            As a user of the bot, you agree not to:
          </p>
          <ul class="lead mb-5" style="display:inline-block;text-align:justify;">
            <li>Systematically retrieve data or other content from the bot to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
            <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
            <li>Circumvent, disable, or otherwise interfere with security-related features of the bot, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the bot and/or the Content contained therein.</li>
            <li>Use any information obtained from the bot in order to harass, abuse, or harm another person.</li>
            <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
            <li>Use the bot in a manner inconsistent with any applicable laws or regulations.</li>
            <li>Upload or transmit (or attempt to upload or to transmit) any harmful material, including excessive use of capital letters and spamming (continuous posting of repetitive text). This also includes anything that may interferes with any part of the bot or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the bot.</li>
            <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
            <li>Use any information obtained from the bot in order to harass, abuse, or harm another person.</li>
            <li>Attempt to impersonate another user or person or use the username of another user.</li>
            <li>Interfere with, disrupt, or create an undue burden on the bot or the networks or services connected to the bot.</li>
            <li>Using the bot to promote activity that may endanger a user's life or the life of any other user or lead to physical harm.</li>
            <li>Using the bot to advertise or offer to sell goods and services.</li>
            <li>Pretending to be the bot in order to manipulate users.</li>
          </ul>
          <p class="lead mb-5">
            If you are aware of any entity engaging in prohibited activities, please report it to us through our <a href="help">support server.</a>.
          </p>
          <h4>Third-Party Website And Content</h4>
          <p class="lead mb-5">
            The bot may contain (or you may be sent via the bot) links to other websites ("Third-Party Websites") as well as articles, photographs, text,
            graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating
            from third parties ("Third-Party Content"). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for
            accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the bot or any
            Third-Party Content posted on, or available through the bot, including the content, accuracy, offensiveness, opinions, reliability, privacy
            practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting
            the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you
            decide to leave the bot and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you
            should be aware these Terms of Service no longer govern. You should review the applicable terms and policies, including privacy and
            data gathering practices, of any website to which you navigate from the bot or relating to any applications you use or install from the bot.
            Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility
            whatsoever in relation to such purchases which are exclusively between you and the applicable third party. You agree and acknowledge that we do
            not endorse the products or services offered on Third-Party Websites and you shall hold us harmless from any harm caused by your purchase of such
            products or services. Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in
            any way from any Third-Party Content or any contact with Third-Party Websites.
          </p>
          <h4>Bot Management</h4>
          <p class="lead mb-5">
            We reserve the right, but not the obligation, to: (1) monitor the bot for violations of these Terms of Service; (2) take appropriate action
            against anyone who, in our sole discretion, violates the law or these Terms of Service, including without limitation, reporting such user to law
            enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable
            (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation,
            notice, or liability, to remove from the bot or otherwise disable all files and content that are excessive or are in any way burdensome
            to our systems; and (5) otherwise manage the bot in a manner designed to protect our rights and property and to facilitate the proper functioning
            of the bot.
          </p>
          <h4>Privacy Policy</h4>
          <p class="lead mb-5">
            Please review our Privacy Policy: <a href="privacy">benjibot.xyz/privacy.</a> By using the bot, you agree to be bound by our Privacy Policy, which is incorporated into these
            Terms of Service. Please be advised the bot and Discord is hosted in the United States. If you access the bot from any other region of the world with laws
            or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use
            of the bot, you are transferring your data to the United States, and you agree to have your data transferred to and processed in the United States. Further, we do
            not knowingly accept, request, or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children's Online Privacy
            Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental
            consent, we will delete that information from the bot as quickly as is reasonably practical.
          </p>
          <h4>Terms And Termination</h4>
          <p class="lead mb-5">
            These Terms of Service shall remain in full force and effect while you use the bot. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF SERVICE, WE RESERVE THE RIGHT TO, IN OUR
            SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE BOT, TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH
            OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF SERVICE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE BOT
            OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
          </p>
          <h4>Modifications And Interruptions</h4>
          <p class="lead mb-5">
            We reserve the right to change, modify, or remove the contents of the bot at any time or for any reason at our sole discretion without notice. We also reserve the right to modify
            or discontinue all or part of the bot without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance
            of the bot.
          </p>
          <h4>Corrections</h4>
          <p class="lead mb-5">
            There may be information on the bot and/or this website that contains typographical errors, inaccuracies, or omissions, including descriptions, availability, and various other information.
            We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the bot and/or website at any time, without prior notice.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
  name: "TermsView",
  setup() {
    useHead({
      title: "BenjiBot - Terms",
      meta: [
        { name: "title", content: "BenjiBot - Terms of Service" },
        { name: "description", content: "BenjiBot | Terms of Service" },
      ],
    })
  }
}
</script>
