import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import VueAnalytics from 'vue3-analytics'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import App from './App.vue'
import router from './router';
import './registerServiceWorker'

const app = createApp(App)
const head = createHead()

app
    .use(router)
    .use(head)
    .use(VueAnalytics, { id: "G-1WT7ZLVCV4" })
    .mount('#app')
